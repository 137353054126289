
import { defineComponent, computed } from "vue";
import { useQuery } from "@vue/apollo-composable"
import { SUBSCRIPTION } from './HomeQueries';
import IntercomService from "@services/intercom/Intercom.service";
import Container from "typedi";



export default defineComponent({
    name: "InfoCards",
    setup(props, ctx) {
        const nameQuery: any = useQuery(SUBSCRIPTION);
        const nameResult = computed(() => nameQuery.result.value?.me ?? undefined)

        return {
            nameResult
        };
    },
    methods: {
        showIntercom() {
            Container.get(IntercomService).showIntercom();
        }
    }
});
