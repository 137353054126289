
import { defineComponent, nextTick, ref } from "vue";
import Utilities from '@utilities/Utilities';
import { CountUp } from 'countup.js';

export default defineComponent({
    name: "Countup",
    props: {
        end: {
            type: Number,
            default: 0,
        },
        prefix: {
            type: String,
            default: '',
        },
        suffix: {
            type: String,
            default: '',
        },
        isDecimal: {
            type: Boolean,
            default: false,
        },
        isOffset: {
            type: Boolean,
            default: false,
        },
        isFormatted: {
            type: Boolean,
            default: false,
        }
    },
    setup() {
        const valueLength = ref<string>('initial');
        const extraLength = 30;

        return {
            valueLength,
            extraLength,
        }
    },
    watch: {
        end() {
            this.valueLength = this.$el.offsetWidth;
            if (this.isOffset) {
                this.$el.style.marginRight = -this.extraLength + 'px';
            }
            this.countUp();
        }
    },
    mounted: function () {
        nextTick(() => {
            this.valueLength = this.$el.offsetWidth;
            if (this.isOffset) {
                this.$el.style.marginRight = -this.extraLength + 'px';
            }
            this.countUp();
        })
    },
    computed: {
        calcValueLength: function (): string {
            return this.valueLength + this.extraLength + 'px';
        },
    },
    methods: {
        getTrimmedValue: function (): string {
            const prefix = this.prefix !== '' ? this.prefix + " " : "";

            const abbreviateNumber = Utilities.getFormatted().abbreviateNumber;
            let formatted: ReturnType<typeof abbreviateNumber> | undefined;
            let end: number;
            let suffix: string;

            if (this.isFormatted) {
                formatted = Utilities.getFormatted().abbreviateNumber(this.end);
                suffix = formatted.suffix + this.suffix;
                end = Number(formatted.value);
            } else {
                end = this.end
                suffix = this.suffix;
            }

            const rawValue = this.isDecimal ? `${prefix}${this.end.toFixed(2)}${suffix}` : `${prefix}${end}${suffix}`;
            const trimmedValue = rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            return trimmedValue;
        },
        countUp: function (): void {
            const prefix = this.prefix !== '' ? this.prefix + " " : "";

            const abbreviateNumber = Utilities.getFormatted().abbreviateNumber;
            let formatted: ReturnType<typeof abbreviateNumber>;
            let end: number;
            let suffix: string;

            if (this.isFormatted) {
                formatted = Utilities.getFormatted().abbreviateNumber(this.end);
                suffix = formatted.suffix + this.suffix;
                end = Number(formatted.value)
            } else {
                end = this.end
                suffix = this.suffix;
                formatted = { isFormatted: false, value: "", suffix: "" };
            }

            const options = {
                duration: 1.8,
                decimal: '.',
                separator: ',',
                useGrouping: true,
                useEasing: true,
                prefix: prefix,
                suffix: suffix,
            };
            if (this.isDecimal || formatted.isFormatted) {
                options['decimalPlaces'] = 2;
            };

            const demo = new CountUp(this.$el, end, options);
            if (!demo.error) {
                demo.start();
            } else {
                console.error(demo.error);
            }
        },
    },
});
