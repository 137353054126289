
import { defineComponent } from "vue";
import HomeInteractor from "@interactors/home/Home.interactor"

export default defineComponent({
    name: "Announcements",
    // props: ["data"]
    data() {



        const data = HomeInteractor.getHomeData()['announcements'];
        return {
            data
        }
    }
});
