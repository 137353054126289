
import { defineComponent } from "vue";
import Countup from "@components/Countup.vue";
import Utilities from "@utilities/Utilities";
import HomeInteractor from "@interactors/home/Home.interactor";

export default defineComponent({
    name: "HomeProgress",
    components: { Countup },
    data() {
        const progress = 0;
        const title = "";
        const route = "";
        return {
            progress,
            title,
            route,
            id: Utilities.getIdGenerator().generateV4(),
        };
    },
    async mounted() {
        const progressData: any = await HomeInteractor.getOnboardingProgress();
        this.progress = progressData.percentage;
        this.title = progressData.title;
        this.route = progressData.route;
    },
});
