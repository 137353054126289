import { gql } from "graphql-tag";

//next invoice due? orders used of max?
const SUBSCRIPTION = gql`
    query {
        me {
            id
            firstName
            lastName
            subscription {
                name
                pricePerMonth
                maxOrders
            }
            site {
                ordersCount
                nextSubscriptionDate
                subBoughtDate
            }
        }
    }
`;

export { SUBSCRIPTION };
