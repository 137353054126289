
import { defineComponent, computed } from "vue";
import Countup from "@components/Countup.vue";
import Utilities from "@utilities/Utilities";
import { useQuery } from "@vue/apollo-composable";
import { SUBSCRIPTION } from './HomeQueries';

export default defineComponent({
    name: "SubStatus",
    components: { Countup },
    watch: {
        subResult() {
            if (this.subResult) {
                if (this.subResult.subscription.pricePerMonth > 0) {
                    this.pricePerMonth = this.subResult.subscription.pricePerMonth / 100;
                } else {
                    this.pricePerMonth = this.subResult.subscription.pricePerMonth;
                }

                const nextInvoiceDate = new Date(this.subResult.site.nextSubscriptionDate);
                nextInvoiceDate.setDate(nextInvoiceDate.getDate() - 1)
                const dateNow = new Date();

                this.nextInvoiceDue = nextInvoiceDate.getDate() + "/" + (nextInvoiceDate.getMonth() + 1) + "/" + nextInvoiceDate.getFullYear();

                const differenceMs = nextInvoiceDate.getTime() - dateNow.getTime();
                const nextInvoiceIn = Math.ceil(differenceMs / (1000 * 3600 * 24));
                this.nextInvoiceIn = nextInvoiceIn;
                this.subBoughtDate = this.subResult.site.subBoughtDate;

                if (this.subBoughtDate === null) {
                    let boughtDate = new Date;

                    if (this.nextInvoiceIn > 0 && this.nextInvoiceIn <= 15) {
                        boughtDate.setDate(boughtDate.getDate() - (15 - this.nextInvoiceIn));
                    } else if (this.nextInvoiceIn > 15 && this.nextInvoiceIn <= 31) {
                        boughtDate.setDate(boughtDate.getDate() - (31 - this.nextInvoiceIn));
                    } else if (this.nextInvoiceIn > 31) {
                        boughtDate.setDate(boughtDate.getDate() - (365 - this.nextInvoiceIn));
                    }

                    this.subBoughtDate = boughtDate;
                } else {
                    this.subBoughtDate = new Date(this.subBoughtDate);
                }


                const subBoughtDaysAgo = Math.ceil((dateNow.getTime() - this.subBoughtDate.getTime()) / (1000 * 3600 * 24));
                if (subBoughtDaysAgo + this.nextInvoiceIn === 15) {
                    this.invoiceBarWidth = (100 / 15) * Math.abs((this.nextInvoiceIn) - 15);
                } else if (subBoughtDaysAgo + this.nextInvoiceIn > 15 && subBoughtDaysAgo + this.nextInvoiceIn <= 31) {
                    this.invoiceBarWidth = (100 / 31) * Math.abs((this.nextInvoiceIn) - 31);
                } else if (subBoughtDaysAgo + this.nextInvoiceIn > 31) {
                    this.invoiceBarWidth = (100 / 365) * Math.abs(365 - this.nextInvoiceIn);
                }

                if (this.invoiceBarWidth > 100) {
                    this.invoiceBarWidth = 100;
                } else if (this.invoiceBarWidth < 0) {
                    this.invoiceBarWidth = 0;
                }
                this.invoiceBarWidth = this.invoiceBarWidth + '%';

                this.ordersBarWidth = Math.floor((100 / this.subResult.subscription.maxOrders) * (this.subResult.site.ordersCount + 1));
                if (this.ordersBarWidth > 100) {
                    this.ordersBarWidth = 100;
                } else if (this.ordersBarWidth < 0) {
                    this.ordersBarWidth = 0;
                }
                this.ordersBarWidth = this.ordersBarWidth + '%';
            }
        }
    },
    setup(props, ctx) {
        const subQuery: any = useQuery(SUBSCRIPTION);
        const subResult = computed(() => subQuery.result.value?.me ?? false)

        const nextInvoiceDue: any = undefined, subBoughtDate: any = 0, ordersBarWidth: any = 0,
            nextInvoiceIn: any = undefined, invoiceBarWidth: any = 0, pricePerMonth: string | number = 0;

        return {
            subResult,
            nextInvoiceDue,
            nextInvoiceIn,
            invoiceBarWidth,
            ordersBarWidth,
            subBoughtDate,
            pricePerMonth
        };
    },
    data() {
        const price = 49;

        const ordersUsed = 125;
        const ordersRemaining = 375;
        const ordersUsedPercentage = Math.floor((100 / (ordersUsed + ordersRemaining)) * ordersUsed);

        const lastInvoiceDateObj: Date = new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            new Date().getDate() + 29
        );
        const nextInvoiceDateObj: Date = new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 2,
            new Date().getDate()
        );

        return {
            price,
            ordersUsed,
            ordersRemaining,
            ordersUsedPercentage,
            ordersID: Utilities.getIdGenerator().generateV4(),
            invoiceID: Utilities.getIdGenerator().generateV4(),
            lastInvoiceDateObj,
            nextInvoiceDateObj,
        }
    },
});
