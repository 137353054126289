import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5139ef4e")
const _hoisted_1 = { class: "card w-100" }
const _hoisted_2 = { class: "card-body d-flex py-4 progress-card-wrapper" }
const _hoisted_3 = { class: "progress-width py-3 d-flex flex-column" }
const _hoisted_4 = { class: "w-100 px-8 d-flex justify-content-between" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "progress-title-imitation"
}
const _hoisted_7 = {
  key: 1,
  class: "progress-percentage-imitation"
}
const _hoisted_8 = { class: "w-100 py-3 px-8" }
const _hoisted_9 = { class: "w-100 h-10px rounded progress-bg" }
const _hoisted_10 = ["id"]
const _hoisted_11 = { class: "button-width d-flex align-items-center" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Countup = _resolveComponent("Countup")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", null, [
            (_ctx.title !== '')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true),
            (_ctx.title === '')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("h5", null, [
            (_ctx.progress !== 0)
              ? (_openBlock(), _createBlock(_component_Countup, {
                  key: 0,
                  end: _ctx.progress,
                  suffix: '%',
                  isOffset: true
                }, null, 8, ["end"]))
              : _createCommentVNode("", true),
            (_ctx.progress === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", {
              class: "h-100 rounded progress-fg",
              id: String(_ctx.id),
              style: _normalizeStyle({
                            width: _ctx.progress + '%'
                        })
            }, null, 12, _hoisted_10)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_router_link, {
          to: '/onboarding/' + _ctx.route
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", {
              "data-recording-click-props": "{\"test\": \"ben-ten-co\"}",
              class: _normalizeClass(["btn button-g", [{ 'text-transparent': _ctx.progress === 0 }]])
            }, _toDisplayString(_ctx.progress !== 100 ? "Continue" : "Review"), 3)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ])
  ]))
}