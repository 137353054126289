import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "kt_content_container",
  class: "container-xxl home-flex py-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (block) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (block.options.isEnabled)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(block.component), {
              key: block,
              style: _normalizeStyle({ 'order': block.options.order })
            }, null, 8, ["style"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 256))
  ]))
}