
import { defineComponent, inject } from "vue";
import RoutingInteractor from "@interactors/routing/Routing.interactor";
import OptionsInteractor from "@interactors/options/Options.interactor";
import HomeProgress from "@components/home/HomeProgress.vue";
import SubStatus from "@components/home/SubStatus.vue"
import InfoCards from "@components/home/InfoCards.vue"
import Announcements from "@components/home/Announcements.vue";
import LatestGuides from "@components/home/LatestGuides.vue";

export default defineComponent({
    name: "Home",
    components: { HomeProgress, SubStatus, InfoCards, Announcements, LatestGuides },
    data() {
        const section = RoutingInteractor.getCurrentSection(false, false, true) as string;
        const subsection = RoutingInteractor.getCurrentSubsection(false, false, true) as string;
        let $mitt: any, options: any;

        return {
            section,
            subsection,
            $mitt,
            options,
        }
    },
    async mounted() {
        this.$mitt = inject('$mitt');
        this.$mitt.on('home-options-update', () => { this.getOptions(); })

        await this.getOptions();
        this.$mitt.emit("view-loading-finish");
    },
    beforeUnmount() {
        this.$mitt.all.delete('home-options-update', () => { this.getOptions(); });
    },
    methods: {
        async getOptions() {
            this.options = await OptionsInteractor.getSectionOptions();
            return
        }
    }
});
