
import { defineComponent } from "vue";
import HomeInteractor from "@interactors/home/Home.interactor"

export default defineComponent({
    name: "LatestGuides",
    data() {
        const originUrl = window.location.origin;

        const videoGuides = HomeInteractor.getHomeData()['guides_video'];
        const writtenGuides = HomeInteractor.getHomeData()['guides_written'];

        return {
            originUrl,
            videoGuides,
            writtenGuides,
        };
    },
    methods: {
        getThumbnail(id: string) {
            return 'https://img.youtube.com/vi/' + id + '/mqdefault.jpg';
        },
        getVideoUrl(id: string) {
            return 'https://youtu.be/' + id;
        }
    }
});
