import Services from "@services/Services";
import { Service } from "typedi";
import RoutingInteractor from "@interactors/routing/Routing.interactor";
import HomeInteractor from "@interactors/home/Home.interactor";

@Service()
export default class OptionsInteractor {
    public static getOptionsLocal(): any | boolean {
        let section = RoutingInteractor.getCurrentSection(true, false);

        if (section && section.split("/").length > 1) {
            section = section.split("/")[1];
            const fLetter = section[0].toUpperCase();
            section = fLetter + section.substring(1);
        }

        const subsection = RoutingInteractor.getCurrentSubsection(
            false,
            false,
            true
        ) as string;
        const sessionOptions: any = sessionStorage.getItem(
            section + "-" + subsection + "-options"
        );

        if (sessionOptions) {
            return JSON.parse(sessionOptions);
        }

        return false;
    }

    public static getDefaultOptions(): any {
        let section = RoutingInteractor.getCurrentSection(true, false);

        if (section && section.split("/").length > 1) {
            section = section.split("/")[1];
            const fLetter = section[0].toUpperCase();
            section = fLetter + section.substring(1);
        }
        const subsection = RoutingInteractor.getCurrentSubsection(
            false,
            false,
            true
        );

        const currentOptions =
            Services.getStaticData()["get" + section + "DefaultOptions"]()[
                subsection
            ];
        return currentOptions;
    }

    public static async getSectionOptions(allSections = false): Promise<any> {
        let section = RoutingInteractor.getCurrentSection(true, false);
        if (section && section.split("/").length > 1) {
            section = section.split("/")[1];
            const fLetter = section[0].toUpperCase();
            section = fLetter + section.substring(1);
        }
        if (allSections) {
            const options = {};
            const sections = RoutingInteractor.getSectionSubsections();
            for (const index in sections) {
                const sessionOptions: any = sessionStorage.getItem(
                    section + "-" + sections[index]["route"] + "-options"
                );
                if (sessionOptions) {
                    const sortedOptions = JSON.parse(sessionOptions);
                    sortedOptions.sort((a, b) =>
                        a.options.order < b.options.order ? -1 : 1
                    );
                    options[sections[index]["route"]] = sortedOptions;
                } else {
                    const currentOptions =
                        Services.getStaticData()[
                            "get" + section + "DefaultOptions"
                        ]()[sections[index]["route"]];

                    const currentOptionsArray = [];

                    currentOptions.sort((a, b) =>
                        a.options.order < b.options.order ? -1 : 1
                    );

                    sessionStorage.setItem(
                        section + "-" + sections[index]["route"] + "-options",
                        JSON.stringify(currentOptions)
                    );
                    options[sections[index]["route"]] = currentOptions;
                }
            }
            return options;
        }

        const subsection = RoutingInteractor.getCurrentSubsection(
            false,
            false,
            true
        ) as string;

        const sessionOptions: string | null = sessionStorage.getItem(
            section + "-" + subsection + "-options"
        );

        if (sessionOptions) {
            const options = JSON.parse(sessionOptions);
            options.sort((a, b) =>
                a.options.order < b.options.order ? -1 : 1
            );
            return options;
        } else {
            //fetch options from BE else take default options and POST to BE
            const currentOptions =
                Services.getStaticData()["get" + section + "DefaultOptions"]()[
                    subsection
                ];
            currentOptions.sort((a, b) =>
                a.options.order < b.options.order ? -1 : 1
            );

            sessionStorage.setItem(
                section + "-" + subsection + "-options",
                JSON.stringify(currentOptions)
            );
            return currentOptions;
        }
    }

    public static updateSectionOptions(options: any): void {
        if (!options) {
            return;
        }

        let section = RoutingInteractor.getCurrentSection(true, false);
        if (section && section.split("/").length > 1) {
            console.log(section.split("/"));
            section = section.split("/")[1];
            const fLetter = section[0].toUpperCase();
            section = fLetter + section.substring(1);
        }

        for (const collection in options) {
            const newOptions = options[collection];
            newOptions.sort((a, b) =>
                a.options.order < b.options.order ? -1 : 1
            );
            sessionStorage.setItem(
                section + "-" + collection + "-options",
                JSON.stringify(newOptions)
            );
        }

        return;
    }

    public static restoreSectionOptions(allSections = false): void {
        let section = RoutingInteractor.getCurrentSection(true, false);
        if (section && section.split("/").length > 1) {
            console.log(section.split("/"));
            section = section.split("/")[1];
            const fLetter = section[0].toUpperCase();
            section = fLetter + section.substring(1);
        }

        const options =
            Services.getStaticData()["get" + section + "DefaultOptions"]();
        const subsection = RoutingInteractor.getCurrentSubsection(
            false,
            false,
            true
        ) as string;

        for (const optionsCollection in options) {
            options[optionsCollection].sort((a, b) =>
                a.options.order < b.options.order ? -1 : 1
            );
        }

        if (!allSections) {
            sessionStorage.setItem(
                section + "-" + subsection + "-options",
                JSON.stringify(options[subsection])
            );
            return;
        }

        for (const option in options) {
            sessionStorage.setItem(
                section + "-" + option + "-options",
                JSON.stringify(options[option])
            );
        }
        return;
    }
}
